/* ======================================================= */
/*                        Media Team                       */
/* ======================================================= */

/* = Variables
---------------------------------------------------------- */
$default-font-color: #495057;
$default-font-color-hover: #17a2b8;
$default-font-family: 'Roboto', Arial, sans-serif;
$header-font-family: "Source Sans Pro", sans-serif;
$main-bg-color: #17a2b8;

/* = Common
---------------------------------------------------------- */
body {
    color: $default-font-color;
    background-color: #ffffff;
    font-family: $default-font-family;
    a {
        color: $default-font-color;
        &:hover {
            color: $default-font-color-hover;
            text-decoration: none;
        }
    }
}

$header-index-s: 2;
@each $index in $header-index-s {
    h#{$index} {
        font-family: $header-font-family;
        font-weight: 600;
    }
}

/* = Menu
---------------------------------------------------------- */
.menu {
    h1 {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
    }
    .navbar-brand {
        font-family: $header-font-family;
        font-size: 1.4rem;
    }
    .nav-item {
        padding-left: 10px;
        padding-right: 10px;
        .nav-link {
            color: #ffffff;
        }
    }
    .navbar-nav {
        .dropdown-menu {
            margin: 0.5rem 0 0;
            border-radius: 0;
            border-top: none;
            padding: 0;
            .dropdown-item {
                color: #ffffff;
                &:hover{
                    color: #000000;
                }
                &:active{
                    background-color: $main-bg-color;
                    color: #ffffff;
                }
            }
        }
    }
}

/* = Search Form
---------------------------------------------------------- */
.search-form {
    position: relative;
    .form-control {
        border-radius: 0;
        border: none;
        &:focus {
            background-color: #ffffff;
            color: #000000;
        }
        &::-webkit-input-placeholder {
            color: #eeeeee;
        }
    }
    .btn-search {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 28px;
        height: 28px;
        color: #fff;
        border: none;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        padding: 0.075rem 0.35rem;
    }
}

/* = Breadcrumb
---------------------------------------------------------- */
.breadcrumb {
    background-color: white;
    padding-left: 0;
    color: white;
    margin-top: 60px;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: "»";
}
/* = Content
----------------------------------------------------------
*/
.content {
    min-height: 550px;
}
/* = List News
----------------------------------------------------------
*/
%list-header {
    font-weight: 600;
    position: relative;
    font-size: 20px;
    display: inline-block;
}

%meta {
    li {
        font-size: 13px;
        color: #969696;
    }
}

.list-news {
    .list-news-header {
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 1px;
        margin-bottom: 5px;
        line-height: 48px;
    }
    h1, h2 {
        @extend %list-header;
        padding-top: 18px;
        font-family: $header-font-family;
        &:before {
            position: absolute;
            content: '';
            background: #d32f2f;
            width: 100%;
            height: 1px;
            bottom: -16px;
            left: 0;
        }
    }
    h3 {
        a {
            font-size: 1rem;
            font-weight: 600;
        }
    }
    ul {
        &.meta {
            @extend %meta;
        }
    }
}

.meta {
    svg {
        margin-top: -3px;
    }
}

/* = Pagination
---------------------------------------------------------- */
.pagination {
    .page-item {
        a {
            color: $default-font-color;
        }
        &:first-child, &:last-child {
            .page-link {
                border-radius: 0;
            }
        }
    }
}

/* = Widget
---------------------------------------------------------- */
.sidebar {
    .card-header {
        @extend %list-header;
        font-family: $header-font-family;
        font-weight: 600;
        line-height: 24px;
    }
    .widget {
        h4 {
            a {
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }
    .list-group-item {
        &.active {
            background-color: #cccccc;
            border: inherit;
            color: #000000;
        }
    }
}

/* = Entry
---------------------------------------------------------- */
.entry {
    h1 {
        font-family: $header-font-family;
        font-size: 1.3rem;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    .entry-meta {
        @extend %meta;
    }
}
