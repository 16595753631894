.entry-content pre {
    border: 1px dashed #CCCCCC;
    background: #EEEEEE;
    padding: 10px;
    margin: 0 0 15px;
    overflow: auto;
    font-size: 13px;
    line-height: 1.6;
    font-family: monospace;
}

//.entry-content blockquote {
//    display: block;
//    background: #fff;
//    padding: 15px 20px 15px 45px;
//    margin: 0 0 20px;
//    position: relative;
//
//    /*Font*/
//    font-family: Georgia, serif;
//    font-size: 16px;
//    line-height: 1.2;
//    color: #666;
//    text-align: justify;
//
//    /*Borders - (Optional)*/
//    border-left: 5px solid #666;
//
//    /*Box Shadow - (Optional)*/
//    -moz-box-shadow: 2px 2px 15px #ccc;
//    -webkit-box-shadow: 2px 2px 15px #ccc;
//    box-shadow: 2px 2px 15px #ccc;
//}
//
//.entry-content blockquote::before {
//    content: "\201C"; /*Unicode for Left Double Quote*/
//
//    /*Font*/
//    font-family: Georgia, serif;
//    font-size: 60px;
//    font-weight: bold;
//    color: #999;
//
//    /*Positioning*/
//    position: absolute;
//    left: 10px;
//    top: 5px;
//}
//
//.entry-content blockquote::after {
//    /*Reset to make sure*/
//    content: "";
//}
//
//.entry-content blockquote a {
//    text-decoration: none;
//    background: #eee;
//    cursor: pointer;
//    padding: 0 3px;
//    color: #c76c0c;
//}
//
//.entry-content blockquote a:hover {
//    color: #666;
//}
//
//.entry-content blockquote em {
//    font-style: italic;
//}

.entry-content h1, .entry-content h2, .entry-content h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'Roboto Condensed',sans-serif;
    font-weight: 600;
    line-height: 1.1;
    color: inherit;
}

.entry-content h1 {
    font-size: 24px;
}

.entry-content h2 {
    font-size: 22px;
}

.entry-content h3 {
    font-size: 20px;
}

.entry-content h4 {
    font-size: 18px;
}

.entry-content ul {
    display: block;
    list-style-type: disc;
    margin: 1em 0 1em 0;
    padding-left: 40px;
}

.entry-content ul li {
    display: list-item;
    list-style-type: inherit;
    margin-top: 5px;
}

.entry-content p {
    line-height: 1.6;
}

.entry-content img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
}

.entry-content img.alignright {
    margin: 5px 0 20px 20px;
}

.entry-content img.alignleft {
    margin: 5px 20px 20px 0;
}

.entry-content a {
    color: #0078b9;
}

.entry-content strong {
    font-weight: bold;
}

.entry-content a:hover {
    text-decoration: underline;
}

/* Table */
.entry-content table thead tr {
    background-color: #00695c;
    color: white;
    font-weight: bold;
}

.entry-content table td, .entry-content table th {
    border: 1px solid #ddd;
    padding: 8px;
}

.entry-content table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.entry-content table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #5f5f5f;
    color: #F1F1F1;
}
